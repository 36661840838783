<template>
  <div class="sora-sender">
    <p>
      CameraID
      <input type="text" v-model="cameraId" />
      <button @click="startConn">接続</button>
      <button @click="closeConn">切断</button>
    </p>
    <p>{{ devices }}</p>
    <div>
      <div class="video_wrapper">
        <video
          ref="video"
          :srcObject="stream"
          playsinline
          autoplay
          muted
          loop
          controls
          style="width: 100%; height: 100%; border: 1px solid black"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import Sora from "sora-js-sdk";
import axios from "axios";

export default {
  name: "SoraView",
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      cameraId: this.id,
      conn: undefined,
      stream: undefined,
      info: undefined,
      devices: undefined,
      reconnectAttempts: 0,
      maxReconnectAttempts: 100,
      reconnectDelay: 10000,
      lastUpdateTime: null,
      connectionMonitor: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.cameraId != undefined) {
        this.startConn();
      }
    });
  },
  methods: {
    async startConn() {
      if (this.cameraId === undefined) {
        return;
      }
      if (this.conn) {
        this.conn.disconnect();
        this.conn = undefined;
      }
      this.reconnectAttempts = 0;
      await this.connectToSora();
    },
    async connectToSora() {
      try {
        const url = "https://api.rocs-lab.com/sora_cloud/" + this.cameraId;
        const response = await axios.get(url, { timeout: 20000 });
        this.info = response.data;

        const channelId = this.info.Item.channel_id;
        const debug = true;
        const sora = Sora.connection("wss://sora.sora-cloud.shiguredo.app/signaling", debug);
        const metadata = { access_token: this.info.Item.access_token };
        const options = {
          multistream: true,
          dataChannelSignaling: true,
          ignoreDisconnectWebSocket: true,
          videoBitRate: 900,
          audioBitRate: 25,
          videoCodecType: "VP9",
        };

        this.conn = sora.sendonly(channelId, metadata, options);

        this.setupEventListeners();

        await this.setupMediaStream();

        console.log("Connection established successfully");
        this.reconnectAttempts = 0;
        this.startConnectionMonitor();
      } catch (error) {
        console.error("Error in connectToSora:", error);
        this.handleReconnection();
      }
    },
    setupEventListeners() {
      this.conn.on("notify", (event) => {
        console.log("notify", event.event_type);
        if (event.event_type === "connection.updated") {
          this.lastUpdateTime = Date.now();
        }
      });

      this.conn.on("disconnect", () => {
        console.log("Disconnected from Sora");
        this.handleReconnection();
      });

      this.conn.on("timeline", (event) => {
        console.log(event);
        if (event.type === "disconnected") {
          this.handleReconnection();
        }
      });
    },
    async setupMediaStream() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.devices = devices.filter(device => device.kind === "videoinput").map(device => device.deviceId);

      const constraints = {
        width: { min: 640, ideal: 1280, max: 1280 },
        height: { min: 360, ideal: 720, max: 720 },
        frameRate: { min: 10, ideal: 20, max: 30 },
        facingMode: { exact: "environment" },
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia({ video: constraints, audio: true });
          await this.conn.connect(this.stream);
        } catch (error) {
          console.error("Error getting user media:", error);
          throw error;
        }
      }
    },
    handleReconnection() {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
        setTimeout(() => this.connectToSora(), this.reconnectDelay);
      } else {
        console.error("Max reconnection attempts reached. Please try manually reconnecting.");
      }
    },
    closeConn() {
      if (this.conn === undefined) {
        return;
      }
      this.conn.disconnect();
      this.stream = undefined;
      this.stopConnectionMonitor();
    },
    startConnectionMonitor() {
      this.lastUpdateTime = Date.now();
      this.connectionMonitor = setInterval(() => {
        const now = Date.now();
        if (now - this.lastUpdateTime > 75000) { // 75秒 = 1分15秒
          console.log("No 'connection.updated' event received for more than 75 seconds. Attempting to reconnect...");
          this.handleReconnection();
        }
      }, 5000); // 5秒ごとにチェック
    },
    stopConnectionMonitor() {
      if (this.connectionMonitor) {
        clearInterval(this.connectionMonitor);
        this.connectionMonitor = null;
      }
    },
  },
  beforeUnmount() {
    this.closeConn();
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>